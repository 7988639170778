import {useStaticQuery, graphql} from 'gatsby';

export const useContactContent = () => {
  const {allContactJson} = useStaticQuery(
    graphql`
      query ContactContent {
        allContactJson {
          nodes {
            title
            icon
            content
          }
        }
      }
    `,
  );
  return allContactJson.nodes;
};
