import React from 'react';
import {Link} from 'gatsby';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './Header.less';

const Header = () => {
  return (
    <Navbar expand="lg" className="navigation" fixed="top">
      <Link to="/" className="navbar-brand navigation__brand">
        RESTART SRL
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav">
        <i className="im im-menu" />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Link to="/" className="nav-link navigation__link">
            ACASĂ
          </Link>
          <Link to="/despre-noi" className="nav-link navigation__link">
            DESPRE NOI
          </Link>
          <Link
            to="/informatii-destinate-publicului"
            className="nav-link navigation__link"
          >
            INFORMAȚII DESTINATE PUBLICULUI
          </Link>
          <Link to="/contact" className="nav-link navigation__link">
            CONTACT
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
