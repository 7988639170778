import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useContactContent} from '../../graphql/contact/contact';
import './Footer.less';

const Footer = () => {
  const contactContent = useContactContent();
  const address = contactContent.find((item) => item.title === 'Adresă');
  const phoneNumbers = contactContent.find((item) => item.title === 'Telefon');
  const email = contactContent.find((item) => item.title === 'Email');

  return (
    <footer className="footer">
      <Container className="footer__container">
        <Row>
          <Col xs={12} md={6} className="my-auto">
            <p className="footer__heading">CONTACTEAZĂ-NE</p>
            <div className="footer__item">
              <i className={address.icon} />
              {address?.content?.map((item, index) => (
                <span
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                />
              ))}
            </div>
            <div className="footer__item">
              <i className={phoneNumbers.icon} />
              <div className="footer__item-inner">
                {phoneNumbers?.content?.map((item, index) => (
                  <span
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="footer__item">
              <i className={email.icon} />
              <div className="footer__item-inner">
                {email?.content?.map((item, index) => (
                  <span
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }}
                  />
                ))}
              </div>
            </div>
            <a
              href="https://www.facebook.com/pages/SC-RESTART-SRL/112307935772745"
              rel="noreferrer"
              target="_blank"
            >
              <div className="footer__item">
                <i className="im im-facebook mt-1 mr-2" />
                <div className="footer__item-inner">RESTART SRL</div>
              </div>
            </a>
          </Col>
          <Col xs={12} md={6} className="my-auto">
            <p className="footer__heading">
              &copy;&nbsp;
              {new Date().getFullYear()}
              &nbsp; RESTART SRL&nbsp;|&nbsp;
              <a href="http://forajecariera.ro">forajecariera.ro</a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
